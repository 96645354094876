<template>
  <div class="elv-source-rule-conditions">
    <el-select
      v-model="conditionData.column"
      popper-class="elv-source-rule-condition-input-popper"
      class="elv-source-rule-condition-type"
      :placeholder="t('common.selectCondition')"
      @change="onChangeConditionType"
    >
      <el-option
        v-for="(item, i) in conditionTypeOption"
        :key="i"
        :label="item.label"
        :value="item.value"
        :disabled="disableConditionType(item.value)"
      />
    </el-select>
    <el-select
      v-if="conditionData.column === 'direction'"
      v-model="conditionData.value"
      popper-class="elv-source-rule-condition-input-popper"
      class="elv-source-rule-condition-long-input"
      placeholder="Select direction"
      @change="onChangeCondition"
    >
      <el-option v-for="(item, i) in directionOption" :key="i" :label="item.label" :value="item.value" />
    </el-select>

    <template v-if="conditionData.column === 'fromAddress' || conditionData.column === 'toAddress'">
      <el-select
        v-model="conditionData.type"
        popper-class="elv-source-rule-condition-input-popper"
        class="elv-source-rule-condition-short-input"
        placeholder="Select"
        @change="onChangeType"
      >
        <el-option
          v-for="(item, i) in addressTypeOption"
          :key="i"
          :label="item.label"
          :value="item.value"
          :disabled="entityId === 78 && item.value === 'CONTACT'"
        />
      </el-select>
      <el-select
        v-if="conditionData.type === 'CONTACT_TYPE' || conditionData.type === 'ACCOUNT_TYPE'"
        v-model="conditionData.value"
        popper-class="elv-source-rule-condition-input-popper"
        class="elv-source-rule-condition-short-input"
        placeholder="Select"
        @change="onChangeCondition"
      >
        <el-option
          v-for="(item, i) in conditionData.type === 'CONTACT_TYPE' ? reportContactType : reportTransactionType"
          :key="i"
          class="elv-source-rule-condition-short-input__option"
          :label="transformI18n(item.label)"
          :value="item.value"
      /></el-select>

      <el-popover
        v-if="conditionData.type === 'ACCOUNT' || conditionData.type === 'CONTACT'"
        ref="addressSelectRef"
        placement="bottom-start"
        trigger="click"
        popper-class="elv-transactions-drawer-address-popper"
        :show-arrow="true"
        :offset="0.2"
        @before-enter="onCheckSelectArrow(true)"
        @before-leave="onCheckSelectArrow(false)"
      >
        <template #reference>
          <div class="elv-source-rule-drawer-address-select">
            <span class="elv-source-rule-drawer-address-select__is">{{ t('common.is') }}</span>
            <div>
              <span>{{ addressData?.name ?? utils.formatInputAddress(props.condition.address) }}</span>
            </div>
            <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: arrowRotate }" />
          </div>
        </template>

        <TransactionOverlayDropdown
          ref="addressOverlayDropdownRef"
          :drop-down-data="conditionData.type === 'ACCOUNT' ? accountsData : contactsData"
          :type="conditionData.type === 'ACCOUNT' ? 'accounts' : 'contacts'"
          :show-checkbox-input="false"
          @onCloseEventSelect="onCloseAddressSelect"
        />
      </el-popover>
    </template>

    <template v-if="conditionData.column === 'amountUsd'">
      <el-select
        v-model="conditionData.operator"
        popper-class="elv-source-rule-condition-input-popper"
        :class="[
          conditionData.operator === 'BETWEEN'
            ? 'elv-source-rule-condition-shorter-input'
            : 'elv-source-rule-condition-short-input'
        ]"
        placeholder="Select"
        @change="onChangeType"
      >
        <el-option v-for="(item, i) in amountTypeOption" :key="i" :label="item.label" :value="item.value" />
      </el-select>

      <el-input
        v-model.number="conditionData.value[0]"
        :class="[
          conditionData.operator === 'BETWEEN'
            ? 'elv-source-rule-condition-shorter-input'
            : 'elv-source-rule-condition-short-input'
        ]"
        @change="onChangeCondition"
      />
      <el-input
        v-if="conditionData.operator === 'BETWEEN'"
        v-model.number="conditionData.value[1]"
        class="elv-source-rule-condition-shorter-input"
        @change="onChangeCondition"
        ><template #prefix><span>and</span></template></el-input
      >
    </template>

    <template v-if="conditionData.column === 'date'">
      <el-select
        v-model="conditionData.operator"
        popper-class="elv-source-rule-condition-input-popper"
        class="elv-source-rule-condition-short-input"
        placeholder="Select"
        @change="onChangeType"
      >
        <el-option v-for="(item, i) in dateTypeOption" :key="i" :label="item.label" :value="item.value" />
      </el-select>
      <div
        ref="dateSelectRef"
        v-click-outside="onCloseDateTime"
        class="elv-source-rule-drawer-address-select"
        @click="onOpenDateTime"
      >
        <div class="elv-source-rule-drawer-time-value">
          <template v-if="conditionData.operator === 'BETWEEN'">
            <template v-if="conditionData.value">
              <span>{{ utils.dateFormatting(conditionData.value[0], 'date') }}</span>
              <span>&nbsp;- {{ utils.dateFormatting(conditionData.value[1], 'date') }}</span>
            </template>
          </template>
          <span v-else>{{
            conditionData.value
              ? utils.dateFormatting(
                  isArray(conditionData.value) ? conditionData.value[0] : conditionData.value,
                  'date'
                )
              : ''
          }}</span>
        </div>
        <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: arrowRotate }" />
      </div>

      <el-date-picker
        ref="datePickerPopoverRef"
        v-model="conditionData.value"
        popper-class="elv-source-rule-date-popper"
        :virtual-ref="dateSelectRef"
        virtual-triggering
        :type="conditionData.operator === 'BETWEEN' ? 'datetimerange' : 'date'"
        value-format="YYYY-MM-DD"
        :clearable="false"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        @change="onChangeDate"
      />
    </template>

    <template v-if="conditionData.column === 'currency'">
      <el-popover
        ref="currencySelectRef"
        placement="bottom-start"
        trigger="click"
        popper-class="elv-transactions-drawer-currency-popper"
        :show-arrow="true"
        :offset="0.2"
        @before-enter="onCheckSelectArrow(true)"
        @before-leave="onCheckSelectArrow(false)"
      >
        <template #reference>
          <div class="elv-source-rule-drawer-currency-select">
            <span class="elv-source-rule-drawer-address-select__is">{{ t('common.is') }}</span>
            <div>
              <span>{{ conditionData.value }}</span>
            </div>
            <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: arrowRotate }" />
          </div>
        </template>
        <el-scrollbar max-height="240px">
          <div
            v-for="item in currenciesData"
            :key="item.symbol"
            class="elv-source-rule-drawer-currency-option"
            @click="onSelectCurrency(item.symbol)"
          >
            <img :src="item.logo" :alt="item.name" />
            <span>{{ item.symbol }}</span>
          </div>
        </el-scrollbar>
      </el-popover>
    </template>

    <div class="elv-source-rule-condition-operating">
      <SvgIcon
        v-if="props.index !== 0"
        name="sources-delete"
        width="18"
        height="18"
        class="elv-source-rule-condition-operating__delete"
        @click="onDeleteCondition"
      />
      <el-tooltip
        ref="elvTableTipsRef"
        :show-arrow="false"
        effect="light"
        placement="top"
        popper-class="elv-table-tips"
        overlay-classname="elv-table-tips"
        :show-after="500"
      >
        <SvgIcon
          name="source-add"
          width="18"
          height="18"
          class="elv-source-rule-condition-operating__add"
          @click="onAddCondition"
        />
        <template #content>
          <div class="elv-table-tips-content">{{ t('message.addSixConditions') }}</div>
        </template>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { transformI18n } from '@/i18n/index'
import { ClickOutside as vClickOutside } from 'element-plus'
import { useReportStore } from '@/stores/modules/reports/index'
import { reportTransactionType, reportContactType } from '@/config/reports'
import { find, pick, flatMap, isEmpty, isArray, includes, capitalize } from 'lodash-es'
import TransactionOverlayDropdown from '../../Transactions/components/OverlayDropdown.vue'

const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  condition: {
    type: Object,
    required: true
  },
  conditionList: {
    type: Array,
    required: true
  },
  model: {
    type: String,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()

const emit = defineEmits(['onConditionChange', 'addCondition'])

const addressData = ref()
const visibleDatePopover = ref(false)
const dateSelectRef = ref()
const addressSelectRef = ref()
const currencySelectRef = ref()
const datePickerPopoverRef = ref()
const addressOverlayDropdownRef = ref()
const arrowRotate = ref('rotate(90deg)')

const conditionTypeOption = reactive([
  { label: 'Transaction Type', value: 'direction' },
  { label: 'From', value: 'fromAddress' },
  { label: 'To', value: 'toAddress' },
  { label: 'Currency', value: 'currency' },
  { label: 'Amount($)', value: 'amountUsd' },
  { label: 'Date', value: 'date' }
])

const directionOption = reactive([
  { label: t('report.inflow'), value: 'IN' },
  { label: t('report.outflow'), value: 'OUT' }
])

const addressTypeOption = reactive([
  { label: t('common.accountType'), value: 'ACCOUNT_TYPE' },
  { label: t('common.account'), value: 'ACCOUNT' },
  { label: t('common.contactType'), value: 'CONTACT_TYPE' },
  { label: t('common.contact'), value: 'CONTACT' }
])

const amountTypeOption = reactive([
  { label: t('report.equals'), value: 'EQ' },
  { label: t('common.doesNotEqual'), value: 'NE' },
  { label: t('common.isGreaterThanOrEqualTo'), value: 'GTE' },
  { label: t('common.isLessThanOrEqualTo'), value: 'LTE' },
  { label: t('common.isBetween'), value: 'BETWEEN' }
])

const dateTypeOption = reactive([
  { label: t('common.on'), value: 'EQ' },
  { label: t('common.before'), value: 'GT' },
  { label: t('common.after'), value: 'LT' },
  { label: t('common.isBetween'), value: 'BETWEEN' }
])

const conditionData: any = ref({ column: '', operator: 'EQ', type: '', value: '' })

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const conditionTypeWidth = computed(() => {
  let width = '100%'
  if (!conditionData.value.column && props.index === 0) {
    return '754px'
  }
  if (!conditionData.value.column) {
    return '728px'
  }
  const wide = ['fromAddress', 'toAddress', 'date', 'amountUsd']
  const wider = ['direction', 'currency']
  if (includes(wide, conditionData.value.column)) {
    if (conditionData.value.column === 'amountUsd' && conditionData.value.operator === 'BETWEEN') {
      width = '176px'
    } else {
      width = '237px'
    }
  }
  if (includes(wider, conditionData.value.column)) {
    width = '360px'
  }
  return width
})

const contactsData = computed(() => {
  if (reportStore.contactList.length) {
    const data = reportStore.contactList.map((itemData: any) => {
      const list = itemData.list.map((listItem: any) => {
        return {
          id: listItem.contactId,
          ...listItem
        }
      })
      return {
        groupName: capitalize(itemData.type),
        children: [{ list }]
      }
    })
    return data
  }
  return []
})

const accountsData = computed(() => {
  if (reportStore.accountList.length) {
    const data = reportStore.accountList.map((itemData: any) => {
      const list = itemData.list.map((listItem: any) => {
        return {
          id: listItem.entityAccountId,
          ...listItem
        }
      })
      return {
        groupName: capitalize(itemData.purpose),
        children: [{ list }]
      }
    })
    return data
  }
  return []
})

const disableConditionType = computed(() => {
  return (column: string) => {
    const columns = props.conditionList.map((item: any) => item.column)
    return includes(columns, column)
  }
})

const onChangeCondition = () => {
  if (!conditionData.value.operator) conditionData.value.operator = 'EQ'
  emit('onConditionChange', props.index, unref(conditionData.value))
}

const onOpenDateTime = () => {
  visibleDatePopover.value = !visibleDatePopover.value
  arrowRotate.value = visibleDatePopover.value ? 'rotate(-90deg)' : 'rotate(90deg)'
  if (visibleDatePopover.value) {
    unref(datePickerPopoverRef)?.handleOpen?.()
  } else {
    unref(datePickerPopoverRef)?.handleClose?.()
  }
}

const onCloseDateTime = () => {
  visibleDatePopover.value = false
  arrowRotate.value = 'rotate(90deg)'
  unref(datePickerPopoverRef)?.handleClose?.()
  onChangeCondition()
}

const onChangeDate = () => {
  visibleDatePopover.value = false
  arrowRotate.value = 'rotate(90deg)'
}

const currenciesData: any = computed(() => {
  return reportStore.currencyList
})

const contactData = computed(() => {
  return (condition: any) => {
    const contactList = flatMap(reportStore.contactList, (item: any) => item.list)
    const predicate: any = {
      value: condition?.value ? condition?.value : condition?.address ?? condition?.cardNumber
    }
    if (condition.platformId) predicate.platformId = condition.platformId
    const contact = find(contactList, predicate)
    return contact
  }
})

const accountData = computed(() => {
  return (condition: any) => {
    const accountList = flatMap(reportStore.accountList, (item: any) => item.list)
    const account = find(accountList, { entityAccountId: condition.value })
    return account
  }
})

const onDeleteCondition = () => {
  if (props.index !== 0) {
    emit('onConditionChange', props.index, conditionData.value, 'delete')
  }
}

const onAddCondition = () => {
  emit('addCondition', props.index)
}

const onChangeType = () => {
  if (conditionData.value.column === 'amountUsd') {
    if (conditionData.value.operator === 'BETWEEN') {
      conditionData.value.value = ['', '']
    } else {
      conditionData.value.value = ['']
    }
  } else {
    conditionData.value.value = ''
  }
  addressData.value = {}
  conditionData.value = pick(conditionData.value, ['column', 'operator', 'value', 'type'])
  onChangeCondition()
}

const onCloseAddressSelect = () => {
  addressData.value = unref(addressOverlayDropdownRef.value?.currentCheckedEvent)
  if (conditionData.value.type === 'ACCOUNT') {
    conditionData.value.value = addressData.value.entityAccountId
  } else {
    if (addressData.value.platformId) conditionData.value.platformId = addressData.value.platformId
    console.log(addressData.value, 888)
    conditionData.value.value = addressData.value.value
    conditionData.value = pick(conditionData.value, ['column', 'operator', 'address', 'type', 'platformId', 'value'])
  }
  console.log(conditionData.value, 123)
  onChangeCondition()
  unref(addressSelectRef)?.hide?.()
}

const onCheckSelectArrow = (status: boolean) => {
  arrowRotate.value = status ? 'rotate(-90deg)' : 'rotate(90deg)'
}

const onSelectCurrency = (symbol: string) => {
  conditionData.value.value = symbol
  unref(currencySelectRef)?.hide?.()
}

// const onChangeAmount = (index: number, $event: any) => {
//   console.log(index, $event, 234)
// }

const onChangeConditionType = (condition: any) => {
  conditionData.value = {
    column: condition,
    operator: '',
    type: '',
    value: condition === 'amountUsd' ? [''] : ''
  }
  onChangeCondition()
}

watchEffect(() => {
  if (!isEmpty(props.condition) && props.model === 'edit') {
    conditionData.value = props.condition
    if (props.condition.column === 'fromAddress' || props.condition.column === 'toAddress') {
      if (props.condition.type === 'CONTACT_TYPE') {
        conditionData.value.value = capitalize(props.condition.value)
      }
      if (props.condition.type === 'ACCOUNT') {
        addressData.value = accountData.value(props.condition)
      }
      if (props.condition.type === 'CONTACT') {
        addressData.value = contactData.value(props.condition)
      }
    }
    if (props.condition.column === 'amountUsd' && props.condition.value === '') {
      conditionData.value.value = props.condition.type === 'BETWEEN' ? ['', ''] : ['']
    }
  }
})
</script>

<style lang="scss">
.elv-source-rule-conditions {
  display: flex;
  position: relative;
  margin-bottom: 8px;

  .elv-source-rule-condition-type {
    .el-input {
      width: v-bind('conditionTypeWidth') !important;
    }
  }

  .elv-source-rule-condition-long-input {
    margin-left: 8px;

    .el-input {
      width: 360px !important;
    }
  }

  .elv-source-rule-condition-short-input {
    width: 237.33px !important;

    margin-left: 8px;

    .el-input {
      width: 237.33px !important;
    }

    &.el-input {
      .el-input__wrapper {
        width: 237.33px !important;
        max-width: 237.33px !important;
      }
    }
  }

  .elv-source-rule-condition-shorter-input {
    width: 176px !important;

    margin-left: 8px;

    .el-input {
      width: 176px !important;
    }

    &.el-input {
      .el-input__wrapper {
        width: 176px !important;
        max-width: 176px !important;
      }
    }
  }

  .elv-source-rule-drawer-address-select {
    box-sizing: border-box;
    height: 36px;
    width: 237.33px;
    padding: 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: relative;
    margin-left: 8px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: border, box-shadow;

    .elv-source-rule-drawer-address-select__is {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #838d95;
      margin-right: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #aaafb6;
        margin-bottom: 8px;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0e0f11;
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

      svg {
        fill: #5e85eb;
      }
    }

    svg {
      position: absolute;
      right: 12px;
      bottom: 8px;
      fill: #838d95;
      transition: transform 0.3s;
    }

    .elv-source-rule-drawer-time-value {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .elv-source-rule-drawer-currency-select {
    box-sizing: border-box;
    height: 36px;
    width: 360px;
    padding: 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: relative;
    margin-left: 8px;

    .elv-source-rule-drawer-address-select__is {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #838d95;
      margin-right: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #aaafb6;
        margin-bottom: 8px;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0e0f11;
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

      svg {
        fill: #5e85eb;
      }
    }

    svg {
      position: absolute;
      right: 12px;
      bottom: 8px;
      fill: #838d95;
      transition: transform 0.3s;
    }
  }

  .elv-source-rule-condition-operating {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    margin-left: 16px;

    svg {
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .elv-source-rule-condition-operating__delete {
      margin-right: 8px;
      fill: #838d95;
    }

    .elv-source-rule-condition-operating__add {
      fill: #838d95;
    }
  }
}

.el-popover.elv-transactions-drawer-currency-popper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 360px !important;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  .elv-source-rule-drawer-currency-option {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    width: 358.5px;
    height: 40px;
    cursor: pointer;

    &:hover {
      background: #f9fafb;
    }

    img {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    span {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #0e0f11;
    }
  }
}

.elv-source-rule-date-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-picker {
    width: 237px;

    .el-picker-panel__content {
      width: 221px;
      margin: 0;
      margin-top: 6px;

      .el-date-table {
        th {
          min-width: 31.57px;
          height: 16px;
          border-bottom: 0px;
          padding: 0px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #aaafb6;
        }

        tr {
          color: #636b75;
        }

        td {
          padding: 0px;
          width: 31.57px;
          height: 27.8px;
          border-radius: 2px;

          &.today .el-date-table-cell__text {
            color: #1e2024;
          }

          &.available:hover {
            border-radius: 2px;
            background: #edf0f3;

            .el-date-table-cell__text {
              color: #1e2024;
              font-weight: 600;
            }
          }

          &.next-month {
            display: none;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          &.prev-month {
            visibility: hidden;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          .el-date-table-cell {
            padding: 0px;
            width: 31.57px;
            height: 27.8px;

            .el-date-table-cell__text {
              width: 31.57px;
              height: 27.8px;
              font-family: 'Barlow';
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px;
            }
          }

          &.in-range .el-date-table-cell {
            background-color: #fff;
          }

          &.current .el-date-table-cell {
            margin-left: 0px;
            border-radius: 0px;

            .el-date-table-cell__text {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
              box-sizing: border-box;
              border-bottom: 1px solid #1343bf;
            }
          }
        }
      }
    }

    .el-picker-panel__body {
      padding: 8px;
    }

    .el-date-picker__header {
      margin: 0;
    }

    .el-date-picker__header-label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #aaafb6;
      padding: 0;
      position: relative;
      top: 4px;

      &:nth-child(3) {
        font-weight: 700;
        color: #1e2024;
        margin-left: 4px;
      }
    }
  }

  .el-date-range-picker {
    &.has-time {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header,
  .el-date-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}

.elv-source-rule-condition-input-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &:not(.is-disabled) &.hover {
      background: #f9fafb;
    }

    &.is-disabled {
      color: #a8abb2;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        width: 32px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
