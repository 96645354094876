<template>
  <div class="elv-source-rule-header">
    <p>{{ t('menus.automation') }}</p>
    <div class="elv-source-rule-header-info">
      <p>{{ t('report.ruleCount', { total: reportStore.sourceConfigRuleList.length }) }}</p>
      <div class="elv-reports-source-operating">
        <div class="elv-reports-source-rule-button">
          <el-button
            :loading="executeLoading"
            class="elv-reports-source-rule-button__execute"
            :class="{ 'is-disabled': reportStore.sourceDoingCount > 0 }"
            @click="openExecuteRulesConfirm"
          >
            <template v-if="reportStore.sourceDoingCount < 1">
              <SvgIcon name="source-rule-execute" width="16" height="16" />
              <span>{{ t('report.manuallyExecuteRules') }}</span>
            </template>
            <span v-else>
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />{{
                t('report.rulesAreExecuting')
              }}</span
            >
          </el-button>
          <div class="elv-reports-source-rule-button__add" @click="addRule">
            <SvgIcon name="source-add" width="16" height="16" />
            <span>{{ t('button.addRule') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-loading.fullscreen.lock="loading" class="elv-source-rule-container">
    <el-table
      ref="tableRef"
      row-key="configRuleId"
      :data="reportStore.sourceConfigRuleList"
      style="width: 100%"
      :max-height="tableHeight"
      border
      :row-class-name="disableRow"
      class="elv-source-rule-table"
    >
      <el-table-column :label="t('common.priority')" width="64">
        <template #default="{ row }">
          <div class="elv-source-rule-table-draggable">
            <SvgIcon width="20" height="20" name="draggable" class="elv-source-rule-table-draggable__icon" />
            <span>{{ row.priority }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="t('common.name')" width="190">
        <template #default="{ row }">
          <span class="elv-sources-rule-table-row__cell-name">{{ utils.formatLanguageContent(row.name) }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('common.conditions')" width="386">
        <template #default="{ row }">
          <div class="elv-sources-rule-table-row__cell-conditions">
            <div
              v-for="(condition, index) in row.conditions"
              :key="index"
              class="elv-sources-rule-table-cell-conditions__item"
            >
              <p class="elv-sources-rule-table-cell-conditions__item-type">
                {{ currentConditionType(condition.column) }}
              </p>
              <p class="elv-sources-rule-table-cell-conditions__item-is">
                <span v-if="condition.column === 'fromAddress' || condition.column === 'toAddress'">
                  <template v-if="condition.type === 'ACCOUNT_TYPE' || condition.type === 'CONTACT_TYPE'">
                    {{ condition.type === 'CONTACT_TYPE' ? t('report.contactType') : t('common.accountType') }}
                  </template>
                  <template v-else>
                    {{ condition.type === 'CONTACT' ? t('common.contact') : t('common.account') }}
                  </template>
                </span>
                <span v-if="condition.column !== 'date' && condition.column !== 'amountUsd'">{{ t('common.is') }}</span>
                <span v-if="condition.column === 'date'">{{ dateOperator(condition.operator) }}</span>
                <span v-if="condition.column === 'amountUsd'">{{ amountOperator(condition.operator) }}</span>
              </p>
              <div class="elv-sources-rule-table-cell-conditions__item-value">
                <template v-if="condition.column === 'direction'">
                  <SvgIcon
                    name="source-rule-trx-type"
                    width="12"
                    height="12"
                    :style="{ transform: condition.value === 'IN' ? 'rotate(180deg)' : 'rotate(0deg)' }"
                  />
                  {{ condition.value === 'IN' ? t('report.inflow') : t('report.outflow') }}
                </template>

                <template v-if="condition.column === 'fromAddress' || condition.column === 'toAddress'">
                  <SvgIcon
                    v-if="condition.type === 'CONTACT_TYPE' && contactIcon(condition.value)"
                    :name="contactIcon(condition.value)"
                    width="12"
                    height="12"
                  />

                  <img
                    v-else-if="condition.type === 'ACCOUNT'"
                    :src="accountData(condition)?.platform?.logo"
                    :alt="accountData(condition)?.platform?.name"
                  />

                  <template v-else-if="condition.type === 'CONTACT'">
                    <img
                      v-if="contactData(condition)?.platform?.logo"
                      :src="contactData(condition)?.platform?.logo"
                      :alt="contactData(condition)?.platform?.name"
                    />
                    <img
                      v-else-if="condition.platformId"
                      :src="platformData(condition.platformId)?.logo"
                      :alt="platformData(condition.platformId)?.name"
                    />
                  </template>

                  <img
                    v-else
                    :src="reportStore.entityDetail.logo ? reportStore.entityDetail.logo : defaultImg"
                    :alt="reportStore.entityDetail.name"
                    :onerror="useDefaultEntityImage"
                  />

                  <span v-if="condition.type === 'CONTACT' || condition.type === 'ACCOUNT'">{{
                    condition.type === 'ACCOUNT'
                      ? accountData(condition)?.name
                      : contactData(condition)?.name ??
                        utils.formatInputAddress(condition?.address ?? condition?.cardNumber)
                  }}</span>

                  <span v-else>{{ accountOrContactType(condition) }}</span>
                </template>

                <template v-if="condition.column === 'date'">
                  <SvgIcon name="source-rule-calendar" width="12" height="12" />
                  <template v-if="condition.operator === 'BETWEEN'">
                    <span>{{ utils.dateFormatting(condition.value[0], 'date') }}</span>
                    <span>&nbsp;&nbsp;- {{ utils.dateFormatting(condition.value[1], 'date') }}</span>
                  </template>
                  <span v-else>{{ condition.value ? utils.dateFormatting(condition.value[0], 'date') : '' }}</span>
                </template>

                <template v-if="condition.column === 'amountUsd'">
                  <span>{{ condition.value?.[0] }}</span>
                  <span v-if="condition.value?.[1]">&nbsp;&nbsp;- {{ condition.value?.[1] }}</span>
                </template>

                <template v-if="condition.column === 'currency'">
                  <img
                    :src="currentCurrencyData(condition.value)?.logo"
                    :alt="currentCurrencyData(condition.value)?.name"
                  />
                  <span> {{ condition.value }}</span>
                </template>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('common.actions')" width="386">
        <template #default="{ row }">
          <div class="elv-sources-rule-table-row__cell-actions">
            <p>
              {{ t('report.categorizeTransactionAs')
              }}<span :style="{ background: currentEventColor(row.categoryNo) }">{{
                utils.formatLanguageContent(currentEvent(row.categoryNo) ?? '')
              }}</span>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('common.status')" :resizable="false" width="120">
        <template #default="{ row, $index }">
          <div class="elv-sources-rule-table-row__cell-switch">
            <el-switch v-model="row.enable" @change="onChangeStatus(row, $event, $index)" />
          </div>
        </template>
      </el-table-column>
      <el-table-column :resizable="false" width="120">
        <template #default="{ row }">
          <div class="elv-sources-rule-table-row__cell-button">
            <SvgIcon name="sources-edit" width="18" height="18" @click="onEditRule(row)" />
            <SvgIcon name="sources-delete" width="18" height="18" @click="onOpenDeleteConfirm(row)" />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <MessageBox
    ref="ruleMessageBoxRef"
    :show-cancel-button="messageBoxType === 'delete'"
    :cancel-button-text="t('button.cancel')"
    :confirm-button-text="messageBoxType === 'message' ? t('button.yes') : t('button.delete')"
    :loading="deleteLoading"
    :title="messageBoxType === 'message' ? t('report.rulesAreExecuting') : t('report.deleteRule')"
    @onConfirmEvent="onConfirmEvent"
    @onCancelEvent="onCancelEvent"
  >
    <template #content>
      <span class="elv-confirm-info-header__title">{{
        messageBoxType === 'message'
          ? t('message.pleaseWaitRules')
          : t('message.deleteRuleMessage', { name: currentRuleData.name })
      }}</span></template
    >
  </MessageBox>

  <MessageBox
    ref="ruleMessageBoxExecutingRef"
    :show-cancel-button="reportStore.sourceDoingCount < 1"
    :confirm-button-text="t('button.yes')"
    :cancel-button-text="t('button.cancel')"
    :title="reportStore.sourceDoingCount < 1 ? t('report.rulesAreExecuting') : t('report.rulesAreExecuting')"
    @onConfirmEvent="onConfirmExecutingEvent"
    @onCancelEvent="onCancelExecutingEvent"
  >
    <template #content>
      <span class="elv-confirm-info-header__title">{{
        reportStore.sourceDoingCount < 1 ? t('message.executeRuleMessage') : t('message.pleaseWaitRules')
      }}</span></template
    >
  </MessageBox>

  <RuleDialog
    ref="ruleDialogRef"
    :title="ruleDialogMode === 'add' ? t('button.addRule') : t('button.editRule')"
    :current-rule-data="currentRuleData"
    :model="ruleDialogMode"
  />
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import Sortable from 'sortablejs'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import { transformI18n } from '@/i18n/index'
import { marketMapColorList } from '@/config'
import { find, flatMap, capitalize } from 'lodash-es'
import MessageBox from '@/components/MessageBox.vue'
import RuleDialog from './components/RuleDialog.vue'
import defaultImg from '@/assets/img/default-logo.png'
import { useGlobalStore } from '@/stores/modules/global'
import { useReportStore } from '@/stores/modules/reports/index'
import defaultEntityImg from '@/assets/img/reports/default-entity-logo.png'
import { reportEventList, reportContactType, reportTransactionType } from '@/config/reports'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const globalStore = useGlobalStore()

const loading = ref(false)
const deleteLoading = ref(false)
const tableRef = ref()
const ruleDialogRef = ref()
const currentRuleData = ref()
const ruleDialogMode = ref('add')
const ruleMessageBoxRef = ref()
const ruleMessageBoxExecutingRef = ref()
const executeLoading = ref(false)
const messageBoxType = ref('message')
const syncTimer: any = ref(null)

const tableHeight = computed(() => {
  return globalStore.showUpGradeInfo ? `calc(100vh - 213px)` : `calc(100vh - 182px)`
})

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const currentEvent = computed(() => {
  return (categoryNo: string) => {
    return find(reportEventList, { categoryNo })?.categoryName
  }
})

const currentEventColor = computed(() => {
  return (categoryNo: string) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const eventColorIndex = find(reportEventList, { categoryNo })?.eventColorIndex ?? 0
    return marketMapColorList[eventColorIndex % 20]
  }
})

const contactIcon = computed(() => {
  return (type: string) => {
    return find(reportContactType, { value: capitalize(type) })?.icon
  }
})

const accountOrContactType = computed(() => {
  return (condition: any) => {
    const typeContent: any = find(condition.type === 'CONTACT_TYPE' ? reportContactType : reportTransactionType, {
      value: condition.type === 'CONTACT_TYPE' ? capitalize(condition.value) : condition.value
    })
    return transformI18n(typeContent?.label) ?? capitalize(condition.value)
  }
})

const contactData = computed(() => {
  return (condition: any) => {
    const contactList = flatMap(reportStore.contactList, (item: any) => item.list)
    const predicate: any = {
      value: condition?.value ? condition?.value : condition?.address ?? condition?.cardNumber
    }
    if (condition.platformId) predicate.platformId = condition.platformId
    const contact = find(contactList, predicate)
    return contact
  }
})

const accountData = computed(() => {
  return (condition: any) => {
    const accountList = flatMap(reportStore.accountList, (item: any) => item.list)
    const account = find(accountList, { entityAccountId: condition.value })
    return account
  }
})

const currentConditionType = computed(() => {
  return (type: string) => {
    let label = ''
    switch (type) {
      case 'direction':
        label = t('report.transactionType')
        break
      case 'fromAddress':
        label = t('report.from')
        break
      case 'toAddress':
        label = t('report.to')
        break
      case 'currency':
        label = t('common.currency')
        break
      case 'amountUsd':
        label = t('common.amount')
        break
      default:
        label = t('common.date')
        break
    }
    return label
  }
})

const dateOperator = computed(() => {
  return (operator: string) => {
    let label = ''
    switch (operator) {
      case 'EQ':
        label = t('common.on')
        break
      case 'GT':
        label = t('common.before')
        break
      case 'LT':
        label = t('common.after')
        break
      default:
        label = t('common.isBetween')
        break
    }
    return label
  }
})

const amountOperator = computed(() => {
  return (operator: string) => {
    let label = ''
    switch (operator) {
      case 'EQ':
        label = t('report.equals')
        break
      case 'NE':
        label = t('common.doesNotEqual')
        break
      case 'GTE':
        label = t('common.isGreaterThan')
        break
      case 'LTE':
        label = t('common.isLessThan')
        break
      default:
        label = t('common.isBetween')
        break
    }
    return label
  }
})

const currentCurrencyData = computed(() => {
  return (symbol: string): any => {
    return find(reportStore.currencyList, { symbol })
  }
})

const platformData = computed(() => {
  return (platformId: number) => {
    return find(globalStore.platformList, { platformId })
  }
})

const openExecuteRulesConfirm = () => {
  if (reportStore.sourceDoingCount < 1) {
    ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
  }
}

const executeRules = async () => {
  try {
    executeLoading.value = true
    await ReportsApi.generateActivity(entityId.value)
    executeLoading.value = false
    reportStore.fetchSourceSyncStatus(entityId.value)
  } catch (error: any) {
    executeLoading.value = false
    ElMessage.error(error?.message)
  }
}

const useDefaultEntityImage = (event: any) => {
  event.target.src = defaultEntityImg
}

const initSortable = () => {
  nextTick(() => {
    const table = tableRef.value.$el.querySelector('.el-table__body-wrapper tbody')
    Sortable.create(table, {
      animation: 300,
      handle: '.elv-source-rule-table-draggable__icon',
      onStart: () => {
        if (!currentEntityPermission.value?.configRule?.update) {
          ElMessage.warning(t('message.noPermission'))
          return
        }
        if (reportStore.sourceDoingCount > 0) {
          messageBoxType.value = 'message'
          ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
        }
      },
      onEnd: async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          try {
            reportStore.sourceConfigRuleList.splice(
              Number(newIndex),
              0,
              reportStore.sourceConfigRuleList.splice(Number(oldIndex), 1)[0]
            )
            const ruleIds = reportStore.sourceConfigRuleList.map((item: any) => item.configRuleId)
            await ReportsApi.editRulePriority(entityId.value, { configRuleIds: ruleIds })
            ElMessage.success(t('message.priorityChanged'))
            reportStore.fetchSourceConfigRuleList(entityId.value)
          } catch (error: any) {
            reportStore.sourceConfigRuleList.splice(
              Number(oldIndex),
              0,
              reportStore.sourceConfigRuleList.splice(Number(newIndex), 1)[0]
            )
            ElMessage.error(error.message)
          }
        }
      }
    })
  })
}

const onOpenDeleteConfirm = (row: any) => {
  if (!currentEntityPermission.value?.configRule?.delete) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (reportStore.sourceDoingCount > 0) {
    messageBoxType.value = 'message'
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    return
  }
  currentRuleData.value = row
  messageBoxType.value = 'delete'
  ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onDeleteRule = async (row: any) => {
  try {
    deleteLoading.value = true
    await ReportsApi.deleteSourceConfigRule(entityId.value, row.configRuleId)
    ElMessage.success(t('message.deleteSuccess'))
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    reportStore.fetchSourceConfigRuleList(entityId.value)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

const onCancelExecutingEvent = () => {
  messageBoxType.value = 'message'
  ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
}

const onConfirmExecutingEvent = () => {
  ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
  if (reportStore.sourceDoingCount < 1) {
    executeRules()
  }
}

const disableRow = (data: any) => {
  if (!data.row.enable) return 'elv-rule-table-is-disabled'
  return ''
}

const onChangeStatus = async (row: any, status: any, index: number) => {
  try {
    if (!currentEntityPermission.value?.configRule?.update) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    if (reportStore.sourceDoingCount > 0) {
      messageBoxType.value = 'message'
      ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
      reportStore.sourceConfigRuleList[index].enable = !status
      return
    }
    loading.value = true
    if (status) {
      await ReportsApi.enableSourceConfigRule(entityId.value, row.configRuleId)
      ElMessage.success(t('message.ruleEnabled'))
    } else {
      await ReportsApi.disableSourceConfigRule(entityId.value, row.configRuleId)
      ElMessage.success(t('message.ruleDisabled'))
    }
    reportStore.fetchSourceConfigRuleList(entityId.value)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}
const addRule = () => {
  if (!currentEntityPermission.value?.configRule?.create) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (reportStore.sourceDoingCount > 0) {
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    return
  }
  ruleDialogMode.value = 'add'
  currentRuleData.value = {}
  ruleDialogRef.value.onCheckRuleDialog()
}

const onEditRule = (row: any) => {
  if (!currentEntityPermission.value?.configRule?.update) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (reportStore.sourceDoingCount > 0) {
    messageBoxType.value = 'message'
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    return
  }
  ruleDialogMode.value = 'edit'
  currentRuleData.value = row
  ruleDialogRef.value.onCheckRuleDialog()
}

const onConfirmEvent = () => {
  if (messageBoxType.value === 'delete') {
    onDeleteRule(currentRuleData.value)
  } else {
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
  }
}
const onCancelEvent = () => {
  ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onStartSync = async () => {
  if (syncTimer.value === null) {
    await reportStore.fetchSourceSyncStatus(entityId.value)
    syncTimer.value = setInterval(async () => {
      await reportStore.fetchSourceSyncStatus(entityId.value)
    }, 5000)
  } else {
    await reportStore.fetchSourceSyncStatus(entityId.value)
  }
}

watch(
  () => route.query,
  async () => {
    if (route.name === 'reports-rules') {
      try {
        loading.value = true
        await reportStore.sourceConfigRuleDataInit(entityId.value)
        onStartSync()

        nextTick(() => {
          initSortable()
        })
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  reportStore.sourceConfigRuleList = []
  // eslint-disable-next-line no-unused-expressions
  syncTimer.value && clearInterval(syncTimer.value)
})
</script>

<style lang="scss">
@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}

.elv-source-rule-header {
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  margin-top: 16px;
  margin-bottom: 11px;

  p {
    font-family: 'Plus Jakarta Sans';
    font-size: 26px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    color: #000;
    margin-bottom: 13px;
  }

  .elv-source-rule-header-info {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    p {
      font-family: Plus Jakarta Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
    }

    .elv-reports-source-rule-button {
      padding-bottom: 4px;
      padding-right: 7px;
      display: flex;
      align-items: center;
      cursor: default;

      .elv-reports-source-rule-button__execute {
        height: 32px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 16px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: #636b75;
        border: 0px;
        padding: 0px;

        &.is-disabled {
          cursor: default;
        }

        &:hover,
        &:focus {
          background-color: #fff;
        }

        svg {
          margin-right: 6px;
        }

        span {
          display: flex;
          align-items: center;

          img {
            width: 12px;
            height: 12px;
            display: block;
            margin-right: 6px;
            animation: loading-rotate 2s linear infinite;
          }
        }
      }

      .elv-reports-source-rule-button__add {
        width: 94px;
        height: 32px;
        padding: 0 7px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        color: #1753eb;
        border: 1px solid #c8d6fa;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04));
        border-radius: 24px;
        transition: all 0.1s;
        cursor: pointer;

        span {
          margin-left: 6px;
        }

        svg {
          fill: #1753eb;
        }

        &:hover {
          color: #1343bf;
          border: 1px solid #5e85eb;
        }
      }
    }
  }
}

.elv-source-rule-container {
  width: 100%;
  height: calc(100vh - 181px);
}

.elv-source-rule-table.el-table {
  --el-table-border-color: #edf0f3;

  .el-table__header {
    .el-table__cell {
      height: 36px;
      padding: 0;
      background: #eef4fb;
      border-bottom-color: #ced7e0;
      border-right-color: #e4e7eb;

      &:nth-last-child(2) {
        .cell {
          justify-content: center;
        }
      }

      .cell {
        padding: 0 10px;
        height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
      }
    }
  }

  &.el-table--border {
    &::after,
    &::before {
      display: none;
    }

    .el-table__inner-wrapper {
      &::after {
        background-color: #ced7e0;
      }
    }
  }

  .el-table__border-left-patch {
    display: none;
  }

  .el-switch {
    --el-switch-on-color: #2f63eb;
    --el-switch-off-color: #dde1e6;
    width: 38px;
    height: 18px;
    border-radius: 20px;
  }

  .el-table__row {
    &:not(.elv-rule-table-is-disabled) {
      &:hover {
        background: #f5f7fa;
      }
    }

    &.elv-rule-table-is-disabled {
      background: #f9fafb;
      opacity: 0.6;
    }

    .el-table__cell {
      padding: 0;

      .cell {
        padding: 0 10px;
        min-height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
        word-break: break-word;
      }

      &:first-child {
        .cell {
          padding-left: 3px;
        }
      }
    }
  }

  .elv-source-rule-table-draggable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: move;
      fill: #dde1e6;

      &:hover {
        fill: #4674eb;
      }
    }

    span {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #aaafb6;
    }
  }

  .elv-sources-rule-table-row__cell-name {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-sources-rule-table-row__cell-conditions {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-sources-rule-table-cell-conditions__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .elv-sources-rule-table-cell-conditions__item-type {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #0e0f11;
    }

    .elv-sources-rule-table-cell-conditions__item-is {
      margin: 0 6px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #636b75;

      span:nth-child(2) {
        margin-left: 4px;
      }
    }

    .elv-sources-rule-table-cell-conditions__item-value {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 2px 6px 2px 4px;
      height: 18px;
      background: #f9fafb;
      border: 1px solid #edf0f3;
      border-radius: 12px;

      svg,
      img {
        display: block;
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }
  }

  .elv-sources-rule-table-row__cell-actions {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #0e0f11;

    p {
      display: flex;
      align-items: center;
    }

    span {
      height: 18px;
      box-sizing: border-box;
      padding: 0 6px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      margin-left: 6px;
      color: #ffffff;
    }
  }

  .elv-sources-rule-table-row__cell-switch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elv-sources-rule-table-row__cell-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;
      fill: #838d95;

      &.is-disabled {
        cursor: not-allowed !important;
        fill: #edf0f3 !important;
      }

      &:first-of-type {
        margin-right: 8px;
      }

      &:hover {
        fill: #1e2024;
      }
    }
  }
}
</style>
