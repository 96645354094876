<template>
  <el-dialog
    v-model="showRuleDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-source-rule-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-source-rule-dialog-header__title">
        {{ props.title }}
      </h4>
    </template>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-position="top">
      <el-form-item :label="t('common.ruleName')" prop="name">
        <el-input v-model="ruleForm.name" placeholder="" class="elv-rule-form-name" />
      </el-form-item>

      <el-form-item :label="t('common.selectConditionsInfo')">
        <div v-if="showRuleDialog" class="elv-rule-form-condition">
          <Conditions
            v-for="(item, index) in conditionList"
            :key="item.index"
            :index="index"
            :condition="item"
            :model="model"
            :condition-list="conditionList"
            @addCondition="addCondition"
            @onConditionChange="onConditionChange"
          />
        </div>
      </el-form-item>
      <el-form-item :label="t('common.performActions')">
        <div class="elv-rule-form-event">
          <el-select v-model="categorizeTransaction" show-input="false" :disabled="true">
            <el-option :label="t('common.categorizeTransaction')" :value="0" />
          </el-select>
          <el-popover
            ref="eventSelectRef"
            placement="bottom-start"
            trigger="click"
            popper-class="elv-transactions-drawer-event-popper"
            :show-arrow="true"
            :offset="0.2"
            @before-enter="onCheckSelectArrow(true)"
            @before-leave="onCheckSelectArrow(false)"
          >
            <template #reference>
              <div class="elv-source-rule-drawer-event-select">
                <span class="elv-source-rule-drawer-event-select__as">as</span>
                <div v-if="currentEventData?.categoryName && currentEventData?.categoryNo !== '00000'">
                  <span>{{ currentEventData?.categoryName }}</span>
                </div>
                <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: arrowRotate }" />
              </div>
            </template>

            <TransactionOverlayDropdown
              ref="eventDropdownRef"
              :drop-down-data="reportStore.eventDataList('select-all')"
              :current-data="currentEventData"
              :show-checkbox-input="false"
              :is-show-edit-drawer="showRuleDialog"
              @onCloseEventSelect="onCloseEventSelect"
            />
          </el-popover>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="elv-rule-form-reviewed">
          {{ t('report.automaticallyReviewed') }} <el-switch v-model="ruleForm.autoReview" />
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-source-rule-dialog-footer__save"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="onClickConnect"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ReportsApi from '@/api/ReportsApi'
import Conditions from './Conditions.vue'
import { reportEventList } from '@/config/reports'
import type { FormInstance, FormRules } from 'element-plus'
import { useReportStore } from '@/stores/modules/reports/index'
import { find, pick, filter, isEmpty, cloneDeep } from 'lodash-es'
import TransactionOverlayDropdown from '../../Transactions/components/OverlayDropdown.vue'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  title: {
    type: String,
    default: ''
  },
  currentRuleData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const reportStore = useReportStore()

const route = useRoute()
const eventSelectRef = ref()
const eventDropdownRef = ref()
const submitLoading = ref(false)
const showRuleDialog = ref(false)
const arrowRotate = ref('rotate(90deg)')
const categorizeTransaction = ref(0)
const ruleForm = ref({
  name: '',
  autoReview: false
})
const ruleFormRef = ref<FormInstance>()

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'Rule Name is required'
  },
  conditions: {
    required: true,
    trigger: 'blur',
    message: 'Conditions is required'
  }
})

const conditionList = ref([
  {
    index: '0',
    column: '',
    operator: 'EQ',
    type: '',
    value: ''
  }
])

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const currentEventData = computed(() => {
  let data: any = {}
  if (eventDropdownRef.value?.currentCheckedEvent?.categoryName) {
    data = eventDropdownRef.value?.currentCheckedEvent
    return data
  }
  data = props.currentRuleData.categoryNo ? find(reportEventList, { categoryNo: props.currentRuleData.categoryNo }) : {}
  return data
})

const conditionValueFormat = computed(() => {
  return (data: any) => {
    if ((data.column === 'fromAddress' || data.column === 'toAddress') && data.type === 'CONTACT_TYPE') {
      return data.value?.toUpperCase()
    }
    if (data.column === 'date' && data.operator !== 'BETWEEN') {
      return [data.value]
    }
    return data.value
  }
})

const saveDisabled = computed(() => {
  const isConditionList = conditionList.value.every((item: any) => {
    if (item.column === 'fromAddress' || item.column === 'toAddress') {
      if (item.type === 'CONTACT') {
        return item.column && item.operator && item.value
      }
      return item.column && item.operator && item.value && item.type
    }
    if (item.column === 'amountUsd' || item.column === 'date') {
      if (item.operator === 'BETWEEN') {
        return item.column && item.operator && item.value && item.value[0] !== '' && item.value[1] !== ''
      }
      return (
        item.column &&
        item.operator &&
        ((item.column === 'amountUsd' && item.value[0] !== '') || (item.column === 'date' && item.value !== ''))
      )
    }
    return item.column && item.operator && item.value
  })
  return !ruleForm.value.name || !currentEventData.value?.categoryNo || !isConditionList
})

// 切换弹窗展示
const onCheckRuleDialog = () => {
  showRuleDialog.value = !showRuleDialog.value
}

const onCheckSelectArrow = (status: boolean) => {
  arrowRotate.value = status ? 'rotate(-90deg)' : 'rotate(90deg)'
}

const onCloseEventSelect = () => {
  unref(eventSelectRef)?.hide?.()
}

const onCloseDialog = () => {
  ruleFormRef.value?.resetFields()
  conditionList.value = [
    {
      index: '0',
      column: '',
      operator: 'EQ',
      type: '',
      value: ''
    }
  ]
}

const onClickConnect = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      try {
        submitLoading.value = true
        const newList = conditionList.value.map((item: any) => {
          return pick(item, ['column', 'operator', 'value', 'type', 'platformId'])
        })
        const conditions = newList.map((item) => {
          return {
            ...item,
            operator: item.operator !== '' ? item.operator : 'EQ',
            value: conditionValueFormat.value(item)
          }
        })
        if (props.model === 'edit') {
          const params = {
            name: ruleForm.value.name,
            conditions,
            categoryNo: currentEventData.value.categoryNo,
            autoReview: ruleForm.value.autoReview
          }
          await ReportsApi.editSourceConfigRule(entityId.value, props.currentRuleData?.configRuleId, params)
          ElMessage.success(t('message.editSuccess'))
        } else {
          const params = {
            name: ruleForm.value.name,
            conditions,
            categoryNo: currentEventData.value.categoryNo,
            autoReview: ruleForm.value.autoReview
          }
          await ReportsApi.addSourceConfigRule(entityId.value, params)
          ElMessage.success(t('message.ruleSavedSuccessfully'))
        }
        reportStore.fetchSourceConfigRuleList(entityId.value)
        submitLoading.value = false
        ruleFormRef.value?.resetFields()
        showRuleDialog.value = false
      } catch (error: any) {
        submitLoading.value = false
        ElMessage.error(error?.message)
        console.log(error)
      }
    }
  })
}

const addCondition = (index: number) => {
  if (conditionList.value.length < 6) {
    conditionList.value.splice(index + 1, 0, {
      index: `${index}${Math.floor(Math.random() * 900) + 100}`,
      column: '',
      operator: 'EQ',
      type: '',
      value: ''
    })
  }
}

// emit 事件
const onConditionChange = (index: number, condition: any, operating: string = 'edit') => {
  if (operating === 'edit') {
    const id = conditionList.value[index].index
    conditionList.value[index] = condition
    conditionList.value[index].index = id
  } else {
    const newList = filter(conditionList.value, (item: any, i: number) => i !== index)
    conditionList.value = newList
  }
}

defineExpose({ onCheckRuleDialog })

watch(
  [() => props.currentRuleData, () => showRuleDialog.value],
  () => {
    if (!isEmpty(props.currentRuleData) && showRuleDialog.value) {
      const currentData = cloneDeep(props.currentRuleData)
      ruleForm.value.name = currentData.name
      ruleForm.value.autoReview = currentData.autoReview
      conditionList.value = currentData.conditions
    }
  },
  { deep: true }
)
</script>

<style lang="scss">
.elv-source-rule-dialog {
  width: 860px;
  min-height: 464px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-source-rule-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-input {
      width: 812px;
      height: 36px;
      border-radius: 4px;

      &.elv-rule-form-name {
        height: 44px;
      }

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }

      .elv-rule-form-reviewed {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;

        .el-switch {
          --el-switch-on-color: #2f63eb;
          --el-switch-off-color: #dde1e6;
          width: 38px;
          height: 18px;
          border-radius: 20px;
          margin-left: 10px;
        }
      }

      .elv-rule-form-condition {
        width: 812px;
        min-height: 52px;
        box-sizing: border-box;
        padding: 8px;
        padding-right: 16px;
        background: #edf0f3;
        border-radius: 4px;
        // :nth-last-child(1) {
        //   margin-bottom: 0px;
        // }
        .elv-source-rule-conditions:last-child {
          margin-bottom: 0px;
        }
      }

      .elv-rule-form-event {
        width: 100%;
        height: 52px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        background: #edf0f3;
        border-radius: 4px;

        .el-select {
          &:first-child {
            .el-input {
              width: 260px;

              &.is-disabled {
                .el-input__inner {
                  color: #0e0f11;
                  cursor: auto;
                }
              }
            }
          }
        }

        .elv-source-rule-drawer-event-select {
          box-sizing: border-box;
          height: 36px;
          width: 394px;
          padding: 4px 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background: #ffffff;
          border: 1px solid #dde1e6;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          position: relative;
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition-property: border, box-shadow;

          .elv-source-rule-drawer-event-select__as {
            font-family: 'Plus Jakarta Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            display: flex;
            align-items: center;
            color: #838d95;
            margin-right: 10px;
          }

          > div {
            display: flex;
            flex-direction: column;

            p {
              font-family: 'Plus Jakarta Sans';
              font-weight: 400;
              font-size: 11px;
              line-height: 15px;
              color: #aaafb6;
              margin-bottom: 8px;
            }

            span {
              font-family: 'Plus Jakarta Sans';
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: #0e0f11;
            }
          }

          &:hover {
            cursor: pointer;
            border: 1px solid #5e85eb;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

            svg {
              fill: #5e85eb;
            }
          }

          svg {
            position: absolute;
            right: 12px;
            bottom: 8px;
            fill: #838d95;
            transition: transform 0.3s;
          }
        }
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;

      &.is-disabled {
        background: #edf0f3;
        color: #838d95;
      }
    }
  }
}

.el-popper.elv-source-rule-drawer-event-popper {
  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
